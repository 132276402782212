import { FC } from "react";

const VideoPlayer: FC = () => {
  return (
    <div className="cs-video_popup">
      <div className="cs-video_popup_overlay"></div>
      <div className="cs-video_popup_content">
        <div className="cs-video_popup_layer"></div>
        <div className="cs-video_popup_container">
          <div className="cs-video_popup_align">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="about:blank"
              ></iframe>
            </div>
          </div>
          <div className="cs-video_popup_close"></div>
        </div>
      </div>
    </div>
  );
};
export { VideoPlayer };
