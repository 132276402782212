import React from "react";
import logo from "./logo.svg";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { PageUp } from "./components/PageUp";
import { VideoPlayer } from "./components/VideoPlayer";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div>
      <div className="cs-preloader cs-center">
        <div className="cs-preloader_in"></div>
      </div>
      <Header />
      <Sidebar />
      <Outlet />
      <Footer />
      <PageUp />
      <VideoPlayer />
    </div>
  );
}

export default App;
