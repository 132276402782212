/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import { About } from "../Pages/About";
import { Index } from "../Pages/Index";
import { ShowcasePortfolio } from "../Pages/ShowcasePortfolio";
import { PhotographyAgency } from "../Pages/PhotographyAgency";
import { MarketingAgency } from "../Pages/MarketingAgency";
import { Pricing } from "../Pages/Pricing";
import { BlogDetails } from "../Pages/BlogDetails";

const { PUBLIC_URL }: any = process.env + "/React";

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="" element={<MarketingAgency />} />
          <Route path="/index" element={<MarketingAgency />} />

          <Route path="/showcase-portfolio" element={<ShowcasePortfolio />} />
          <Route path="/photography-agency" element={<PhotographyAgency />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog-details" element={<BlogDetails />} />

          <Route path="*" element={<Navigate to="" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
