import { FC, useEffect } from "react";

const ShowcasePortfolio: FC = () => {
  useEffect(() => {}, []);
  return (
    <div className="cs-swiper_parallax_slider_wrap">
      <div className="cs-hero_social_wrap cs-primary_font cs-primary_color">
        <div className="cs-hero_social_title">Follow Us</div>
        <ul className="cs-hero_social_links">
          <li>
            <a href="#">Behance</a>
          </li>
          <li>
            <a href="#">Twitter</a>
          </li>
        </ul>
      </div>
      <div className="cs-parallax_slider loading">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="cs-hero cs-style4">
              <a href="case-study.html" className="cs-hero_link" />
              <figure
                className="cs-swiper_parallax_bg"
                data-src="assets/img/slider_1.jpeg"
              >
                <img
                  src="assets/img/slider_1.jpeg"
                  alt="Slider"
                  className="cs-entity_img"
                />
              </figure>
              <div className="cs-hero_text">
                <h2 className="cs-hero_title">
                  <span>Think reality</span>
                </h2>
                <h2 className="cs-hero_title">
                  <span>do positive</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cs-hero cs-style4">
              <a href="case-study.html" className="cs-hero_link" />
              <figure
                className="cs-swiper_parallax_bg"
                data-src="assets/img/slider_2.jpeg"
              >
                <img
                  src="assets/img/slider_2.jpeg"
                  alt="Slider"
                  className="cs-entity_img"
                />
              </figure>
              <div className="cs-hero_text">
                <h2 className="cs-hero_title">
                  <span>Think reality</span>
                </h2>
                <h2 className="cs-hero_title">
                  <span>do positive</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cs-hero cs-style4">
              <a href="case-study.html" className="cs-hero_link" />
              <figure
                className="cs-swiper_parallax_bg"
                data-src="assets/img/slider_3.jpeg"
              >
                <img
                  src="assets/img/slider_3.jpeg"
                  alt="Slider"
                  className="cs-entity_img"
                />
              </figure>
              <div className="cs-hero_text">
                <h2 className="cs-hero_title">
                  <span>Think reality</span>
                </h2>
                <h2 className="cs-hero_title">
                  <span>do positive</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="cs-hero cs-style4">
              <a href="case-study.html" className="cs-hero_link" />
              <figure
                className="cs-swiper_parallax_bg"
                data-src="assets/img/slider_4.jpeg"
              >
                <img
                  src="assets/img/slider_4.jpeg"
                  alt="Slider"
                  className="cs-entity_img"
                />
              </figure>
              <div className="cs-hero_text">
                <h2 className="cs-hero_title">
                  <span>Think reality</span>
                </h2>
                <h2 className="cs-hero_title">
                  <span>do positive</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        {/* If we need navigation buttons */}
        <div className="cs-swiper_pagination" />
        <div className="cs-swiper_button_prev">
          <svg
            width={26}
            height={13}
            viewBox="0 0 26 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.469791 5.96967C0.176899 6.26256 0.176899 6.73744 0.469791 7.03033L5.24276 11.8033C5.53566 12.0962 6.01053 12.0962 6.30342 11.8033C6.59632 11.5104 6.59632 11.0355 6.30342 10.7426L2.06078 6.5L6.30342 2.25736C6.59632 1.96447 6.59632 1.48959 6.30342 1.1967C6.01053 0.903806 5.53566 0.903806 5.24276 1.1967L0.469791 5.96967ZM26.0001 5.75L1.00012 5.75V7.25L26.0001 7.25V5.75Z"
              fill="currentColor"
            />
          </svg>
          Prev
        </div>
        <div className="cs-swiper_button_next">
          Next
          <svg
            width={26}
            height={13}
            viewBox="0 0 26 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5305 7.03033C25.8233 6.73744 25.8233 6.26256 25.5305 5.96967L20.7575 1.1967C20.4646 0.903806 19.9897 0.903806 19.6968 1.1967C19.4039 1.48959 19.4039 1.96447 19.6968 2.25736L23.9395 6.5L19.6968 10.7426C19.4039 11.0355 19.4039 11.5104 19.6968 11.8033C19.9897 12.0962 20.4646 12.0962 20.7575 11.8033L25.5305 7.03033ZM0.00012207 7.25H25.0001V5.75H0.00012207V7.25Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
export { ShowcasePortfolio };
