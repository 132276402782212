import { FC } from "react";

const BlogDetails: FC = () => {
  return (
    <>
      <div
        className="cs-page_heading cs-style1 cs-center text-center cs-bg"
        data-src="assets/img/blog_details_hero_bg.jpeg"
      >
        <div className="container">
          <div className="cs-page_heading_in">
            <h1 className="cs-page_title cs-font_50 cs-white_color">
              Blog Single
            </h1>
            <ol className="breadcrumb text-uppercase">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active">Blog Single</li>
            </ol>
          </div>
        </div>
      </div>
      {/* End Hero */}
      <div className="cs-height_150 cs-height_lg_80" />
      {/* Start Blogs */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="cs-post cs-style2">
                <div className="cs-post_thumb cs-radius_15">
                  <img
                    src="assets/img/post_5.jpeg"
                    alt="Post"
                    className="w-100 cs-radius_15"
                  />
                </div>
                <div className="cs-post_info">
                  <div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                    <span className="cs-posted_by">07 Mar 2022</span>
                    <a href="#" className="cs-post_avatar">
                      Tech
                    </a>
                  </div>
                  <h2 className="cs-post_title">
                    Creative studio programm coming soon
                  </h2>
                  <p>
                    Elit scelerisque mauris pellentesque pulvinar pellentesque
                    habitant morbi tristique. Tortor posuere ac ut consequat
                    semper viverra nam libero justo. Mauris commodo quis
                    imperdiet massa tincidunt nunc pulvinar sapien et. Aliquam
                    purus sit amet luctus venenatis lectus magna fringilla urna.
                    Purus sit amet luctus venenatis lectus. Nunc aliquet
                    bibendum enim facilisis. Pretium viverra suspendisse potenti
                    nullam ac tortor vitae.
                  </p>
                  <blockquote className="cs-primary_font">
                    {" "}
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, who expound the
                    actual teachings of the great explorer of the truth, the
                    master. <small>Loren Mulari</small>
                  </blockquote>
                  <p>
                    On the other hand, we denounce with righteous indignation
                    and dislike men who are so beguiled and demoralized by the
                    charms of pleasure of the moment, so blinded by desire, that
                    they cannot foresee the pain and trouble that are bound to
                    ensue; and equal blame belongs to those who fail in their
                    duty through weakness of will, which is the same as saying
                    through shrinking from toil and pain. These cases are
                    perfectly simple and easy to distinguish. In a free hour,
                    when our power of choice is untrammelled and when nothing
                    prevents our being able to do what we like best, every
                    pleasure is to be welcomed and every pain avoided. But in
                    certain circumstances and owing to the claims of duty or the
                    obligations of business it will frequently occur that
                    pleasures have to be repudiated.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src="assets/img/blog_details_img_1.jpeg"
                        alt="Blog Details"
                        className="cs-radius_15 w-100"
                      />
                      <div className="cs-height_45 cs-height_lg_45" />
                    </div>
                    <div className="col-md-6">
                      <img
                        src="assets/img/blog_details_img_2.jpeg"
                        alt="Blog Details"
                        className="cs-radius_15 w-100"
                      />
                      <div className="cs-height_45 cs-height_lg_45" />
                    </div>
                  </div>
                  <h3>Art is powerful and imaginary source</h3>
                  <p>
                    we denounce with righteous indignation and dislike men who
                    are so beguiled and demoralized by the charms of pleasure of
                    the moment, so blinded by desire, that they cannot foresee
                    the pain and trouble that are bound to ensue; and equal
                    blame belongs to those who fail in their duty through
                    weakness of will, which is the same as saying through
                    shrinking from toil and pain. These cases are perfectly
                    simple and easy to distinguish. In a free hour, when our
                    power of choice is untrammelled and when nothing prevents
                    our being able to do what we like best, every pleasure is to
                    be welcomed and every pain avoided. But in certain
                    circumstances.
                  </p>
                  <p>
                    So, blinded by desire, that they cannot foresee the pain and
                    trouble that are bound to ensue; and equal blame belongs to
                    those who fail in their duty through weakness of will, which
                    is the same as saying through shrinking from toil and pain.
                    These cases are perfectly simple and easy to distinguish. In
                    a free hour, when our power of choice is untrammelled and
                    when nothing prevents our being able to do what we like
                    best, every pleasure is to be welcomed and every pain
                    avoided. But in certain amount of people.
                  </p>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30" />
              <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
              <div className="cs-height_5 cs-height_lg_5" />
              <p className="cs-m0">
                Your email address will not be published. Required fields are
                marked *
              </p>
              <div className="cs-height_40 cs-height_lg_30" />
              <form action="#" className="row">
                <div className="col-lg-6">
                  <label>Full Name*</label>
                  <input type="text" className="cs-form_field" />
                  <div className="cs-height_20 cs-height_lg_20" />
                </div>
                <div className="col-lg-6">
                  <label>Email*</label>
                  <input type="text" className="cs-form_field" />
                  <div className="cs-height_20 cs-height_lg_20" />
                </div>
                <div className="col-lg-12">
                  <label>Website*</label>
                  <input type="text" className="cs-form_field" />
                  <div className="cs-height_20 cs-height_lg_20" />
                </div>
                <div className="col-lg-12">
                  <label>Write Your Comment*</label>
                  <textarea
                    cols={30}
                    rows={7}
                    className="cs-form_field"
                    defaultValue={""}
                  />
                  <div className="cs-height_25 cs-height_lg_25" />
                </div>
                <div className="col-lg-12">
                  <button className="cs-btn cs-style1">
                    <span>Send Message</span>
                    <svg
                      width={26}
                      height={12}
                      viewBox="0 0 26 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-xl-3 col-lg-4 offset-xl-1">
              <div className="cs-height_0 cs-height_lg_80" />
              <div className="cs-sidebar cs-right_sidebar cs-accent_5_bg_2">
                <div className="cs-sidebar_item widget_search">
                  <h4 className="cs-sidebar_widget_title">Search</h4>
                  <form className="cs-sidebar_search" action="#">
                    <input type="text" placeholder="Search..." />
                    <button className="cs-sidebar_search_btn">
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </form>
                </div>
                <div className="cs-sidebar_item">
                  <h4 className="cs-sidebar_widget_title">Recent Posts</h4>
                  <ul className="cs-recent_posts">
                    <li>
                      <div className="cs-recent_post">
                        <a href="#" className="cs-recent_post_thumb">
                          <div
                            className="cs-recent_post_thumb_in cs-bg"
                            data-src="assets/img/recent_post_1.jpeg"
                          />
                        </a>
                        <div className="cs-recent_post_info">
                          <h3 className="cs-recent_post_title">
                            <a href="#">How to studio setup...</a>
                          </h3>
                          <div className="cs-recent_post_date cs-primary_40_color">
                            15 Aug 2022
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cs-recent_post">
                        <a href="#" className="cs-recent_post_thumb">
                          <div
                            className="cs-recent_post_thumb_in cs-bg"
                            data-src="assets/img/recent_post_2.jpeg"
                          />
                        </a>
                        <div className="cs-recent_post_info">
                          <h3 className="cs-recent_post_title">
                            <a href="#">Creative people mind...</a>
                          </h3>
                          <div className="cs-recent_post_date cs-primary_40_color">
                            15 Aug 2022
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cs-recent_post">
                        <a href="#" className="cs-recent_post_thumb">
                          <div
                            className="cs-recent_post_thumb_in cs-bg"
                            data-src="assets/img/recent_post_3.jpeg"
                          />
                        </a>
                        <div className="cs-recent_post_info">
                          <h3 className="cs-recent_post_title">
                            <a href="#">AI take over human...</a>
                          </h3>
                          <div className="cs-recent_post_date cs-primary_40_color">
                            15 Aug 2022
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="cs-recent_post">
                        <a href="#" className="cs-recent_post_thumb">
                          <div
                            className="cs-recent_post_thumb_in cs-bg"
                            data-src="assets/img/recent_post_4.jpeg"
                          />
                        </a>
                        <div className="cs-recent_post_info">
                          <h3 className="cs-recent_post_title">
                            <a href="#">You should now add...</a>
                          </h3>
                          <div className="cs-recent_post_date cs-primary_40_color">
                            15 Aug 2022
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Blogs */}
      <div className="cs-height_150 cs-height_lg_80" />
      {/* Start CTA */}
      <section>
        <div className="container">
          <div
            className="cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1"
            data-src="assets/img/cta_bg.jpeg"
          >
            <div className="cs-shape_1" />
            <div className="cs-shape_1" />
            <div className="cs-shape_1" />
            <div className="cs-cta_in">
              <h2 className="cs-cta_title cs-semi_bold cs-m0">
                Let’s disscuse make <br />
                something <i>cool</i> together{" "}
              </h2>
              <div className="cs-height_70 cs-height_lg_30" />
              <a href="contact.html" className="cs-text_btn">
                <span>Apply For Meeting</span>
                <svg
                  width={26}
                  height={12}
                  viewBox="0 0 26 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.5307 6.53033C25.8236 6.23744 25.8236 5.76256 25.5307 5.46967L20.7577 0.696699C20.4648 0.403806 19.99 0.403806 19.6971 0.696699C19.4042 0.989593 19.4042 1.46447 19.6971 1.75736L23.9397 6L19.6971 10.2426C19.4042 10.5355 19.4042 11.0104 19.6971 11.3033C19.99 11.5962 20.4648 11.5962 20.7577 11.3033L25.5307 6.53033ZM0.000366211 6.75H25.0004V5.25H0.000366211V6.75Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export { BlogDetails };
