import { FC } from "react";
import { Link } from "react-router-dom";

const Sidebar: FC = () => {
  return (
    <div className="cs-side_header">
      <button className="cs-close"></button>
      <div className="cs-side_header_overlay"></div>
      <div className="cs-side_header_in">
        <div className="cs-side_header_shape"></div>
        <a className="cs-site_branding" href="index.html">
          <img
            src="assets/img/ElecDraw.png"
            alt="Logo"
            style={{ height: "30px" }}
          />
        </a>
        <div className="cs-side_header_box">
          <h2 className="cs-side_header_heading">
            embark on this exciting journey with us
          </h2>
        </div>
        <div className="cs-side_header_box">
          <h3 className="cs-side_header_title cs-primary_color">Contact Us</h3>
          <ul className="cs-contact_info cs-style1 cs-mp0">
            <li>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.08 11.82 12.69 11.9 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.79L5.82 5.58C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM9 0V10L12 7H18V0H9Z"
                  fill="#FF4A17"
                />
              </svg>
              <span>+91 992 1226 499</span>
            </li>
            <li>
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6.98V16C20 17.1 19.1 18 18 18H2C0.9 18 0 17.1 0 16V4C0 2.9 0.9 2 2 2H12.1C12.04 2.32 12 2.66 12 3C12 4.48 12.65 5.79 13.67 6.71L10 9L2 4V6L10 11L15.3 7.68C15.84 7.88 16.4 8 17 8C18.13 8 19.16 7.61 20 6.98ZM14 3C14 4.66 15.34 6 17 6C18.66 6 20 4.66 20 3C20 1.34 18.66 0 17 0C15.34 0 14 1.34 14 3Z"
                  fill="#FF4A17"
                />
              </svg>
              <span>info@elecdraw.com</span>
            </li>
          </ul>
        </div>
        <div className="cs-side_header_box">
          <h3 className="cs-side_header_title cs-primary_color">Subscribe</h3>
          <div className="cs-newsletter cs-style1">
            <form action="#" className="cs-newsletter_form">
              <input
                type="email"
                className="cs-newsletter_input"
                placeholder="example@work.com"
              />
              <button className="cs-newsletter_btn">
                <span>Send</span>
              </button>
            </form>
            <div className="cs-newsletter_text">
              Subscribe to our newsletter - Get the latest ElecDraw news,
              industry insights, and expert tips.
            </div>
          </div>
        </div>
        <div className="cs-side_header_box">
          <div className="cs-social_btns cs-style1">
            <Link
              to="https://www.linkedin.com/company/elecdraw/about/"
              target="_blank"
              className="cs-center"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.04799 13.7497H1.45647V5.4043H4.04799V13.7497ZM2.75084 4.2659C1.92215 4.2659 1.25 3.57952 1.25 2.75084C1.25 2.35279 1.40812 1.97105 1.68958 1.68958C1.97105 1.40812 2.35279 1.25 2.75084 1.25C3.14888 1.25 3.53063 1.40812 3.81209 1.68958C4.09355 1.97105 4.25167 2.35279 4.25167 2.75084C4.25167 3.57952 3.57924 4.2659 2.75084 4.2659ZM13.7472 13.7497H11.1613V9.68722C11.1613 8.71903 11.1417 7.4774 9.81389 7.4774C8.46652 7.4774 8.26004 8.5293 8.26004 9.61747V13.7497H5.67132V5.4043H8.15681V6.54269H8.19308C8.53906 5.887 9.38421 5.19503 10.6451 5.19503C13.2679 5.19503 13.75 6.92215 13.75 9.16546V13.7497H13.7472Z"
                  fill="white"
                ></path>
              </svg>
            </Link>

            <a href="#" className="cs-center">
              <svg
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4888 1.48066C12.345 0.939353 11.9215 0.513038 11.3837 0.368362C10.4089 0.105469 6.5 0.105469 6.5 0.105469C6.5 0.105469 2.59116 0.105469 1.61633 0.368362C1.07853 0.513061 0.65496 0.939353 0.5112 1.48066C0.25 2.4618 0.25 4.50887 0.25 4.50887C0.25 4.50887 0.25 6.55595 0.5112 7.53709C0.65496 8.0784 1.07853 8.48695 1.61633 8.63163C2.59116 8.89452 6.5 8.89452 6.5 8.89452C6.5 8.89452 10.4088 8.89452 11.3837 8.63163C11.9215 8.48695 12.345 8.0784 12.4888 7.53709C12.75 6.55595 12.75 4.50887 12.75 4.50887C12.75 4.50887 12.75 2.4618 12.4888 1.48066V1.48066ZM5.22158 6.36746V2.65029L8.48861 4.50892L5.22158 6.36746V6.36746Z"
                  fill="white"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Sidebar };
