import { FC } from "react";
import { Link } from "react-router-dom";

const Footer: FC = () => {
  return (
    <footer className="cs-fooer">
      <div className="cs-fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <div className="cs-text_widget">
                  <img
                    src="assets/img/ElecDraw.png"
                    style={{ height: "30px" }}
                    alt=""
                  />
                  <p>
                    Welcome to ElecDraw, where the <br />
                    future of electrical design and schematic creation unfolds.
                  </p>
                </div>
                <div className="cs-social_btns cs-style1">
                  <Link
                    to="https://www.linkedin.com/company/elecdraw/about/"
                    target="_blank"
                    className="cs-center"
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.04799 13.7497H1.45647V5.4043H4.04799V13.7497ZM2.75084 4.2659C1.92215 4.2659 1.25 3.57952 1.25 2.75084C1.25 2.35279 1.40812 1.97105 1.68958 1.68958C1.97105 1.40812 2.35279 1.25 2.75084 1.25C3.14888 1.25 3.53063 1.40812 3.81209 1.68958C4.09355 1.97105 4.25167 2.35279 4.25167 2.75084C4.25167 3.57952 3.57924 4.2659 2.75084 4.2659ZM13.7472 13.7497H11.1613V9.68722C11.1613 8.71903 11.1417 7.4774 9.81389 7.4774C8.46652 7.4774 8.26004 8.5293 8.26004 9.61747V13.7497H5.67132V5.4043H8.15681V6.54269H8.19308C8.53906 5.887 9.38421 5.19503 10.6451 5.19503C13.2679 5.19503 13.75 6.92215 13.75 9.16546V13.7497H13.7472Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                  <a href="#" className="cs-center">
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.4888 1.48066C12.345 0.939353 11.9215 0.513038 11.3837 0.368362C10.4089 0.105469 6.5 0.105469 6.5 0.105469C6.5 0.105469 2.59116 0.105469 1.61633 0.368362C1.07853 0.513061 0.65496 0.939353 0.5112 1.48066C0.25 2.4618 0.25 4.50887 0.25 4.50887C0.25 4.50887 0.25 6.55595 0.5112 7.53709C0.65496 8.0784 1.07853 8.48695 1.61633 8.63163C2.59116 8.89452 6.5 8.89452 6.5 8.89452C6.5 8.89452 10.4088 8.89452 11.3837 8.63163C11.9215 8.48695 12.345 8.0784 12.4888 7.53709C12.75 6.55595 12.75 4.50887 12.75 4.50887C12.75 4.50887 12.75 2.4618 12.4888 1.48066V1.48066ZM5.22158 6.36746V2.65029L8.48861 4.50892L5.22158 6.36746V6.36746Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item" style={{ textAlign: "center" }}>
                <h2 className="cs-widget_title">Pages</h2>
                <ul className="cs-menu_widget cs-mp0">
                  <li>
                    <a href="service-details.html">Pricing</a>
                  </li>
                  <li>
                    <a href="service-details.html">Careers</a>
                  </li>
                  <li>
                    <a href="service-details.html">Blogs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <h2 className="cs-widget_title">Contact Us</h2>
                <ul className="cs-menu_widget cs-mp0">
                  <li>Hinjewadi, Pune, 411 057</li>
                  <li>+91 992 1226 499</li>
                  <li>info@elecdraw.com</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs-footer_item">
                <h2 className="cs-widget_title">Subscribe</h2>
                <div className="cs-newsletter cs-style1">
                  <form action="#" className="cs-newsletter_form">
                    <input
                      type="email"
                      className="cs-newsletter_input"
                      placeholder="example@work.com"
                    />
                    <button className="cs-newsletter_btn">
                      <span>Send</span>
                    </button>
                  </form>
                  <div className="cs-newsletter_text">
                    Subscribe to our newsletter - Get the latest ElecDraw news,
                    industry insights, and expert tips.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs-bottom_footer">
          <div className="cs-bottom_footer_left">
            <div className="cs-copyright">Copyright © 2024 ElecDraw.</div>
          </div>
          <div className="cs-bottom_footer_right">
            <ul className="cs-footer_links cs-mp0">
              <li>
                <a href="">Terms of Use</a>
              </li>
              <li>
                <a href="">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
export { Footer };
