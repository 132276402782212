import { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
  return (
    <header className="cs-site_header cs-style1 text-uppercase cs-sticky_header">
      <div className="cs-main_header">
        <div className="container">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <Link className="cs-site_branding" to="/">
                <img
                  src="assets/img/ElecDraw.png"
                  alt="Logo"
                  style={{ height: "30px" }}
                />
              </Link>
            </div>
            <div className="cs-main_header_center">
              <div className="cs-nav cs-primary_font cs-medium">
                <ul className="cs-nav_list">
                  {/* <li>
                    <Link to="/">Home</Link>
                  </li> */}

                  <li>
                    <a href="service.html">Features</a>
                  </li>

                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <a href="service.html">Marketplace</a>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="service.html">Company</a>
                    <ul>
                      <li>
                        <a href="about.html">About</a>
                      </li>
                      <li>
                        <a href="#">Team</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      <li>
                        <a href="#">FAQ</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="cs-main_header_right">
              <div style={{ paddingRight: "20px" }}>
                <a
                  className="cs-btn cs-style1"
                  style={{ padding: "10px 30px" }}
                  href="https://app.elecdraw.com/auth"
                  target="_blank"
                >
                  <span>Signup</span>
                </a>
              </div>
              <div className="cs-toolbox">
                <span className="cs-icon_btn">
                  <span className="cs-icon_btn_in">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export { Header };
