import { FC } from "react";
import { Link } from "react-router-dom";

const Pricing: FC = () => {
  return (
    <>
      {/* Start Pricing Plan */}
      <section className="cs-hero cs-style3">
        <div className="container">
          <div className="cs-tabs cs-style1">
            <div className="cs-tabs_heading">
              <div className="cs-section_heading cs-style1">
                <h3 className="cs-section_subtitle">Pricing </h3>
                <h2 className="cs-section_title">
                  Stay tuned <br />
                  for pricing update
                </h2>
              </div>
              {/* <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
                <li>
                  <a href="#tab_1">Monthly</a>
                </li>
                <li className="active">
                  <a href="#tab_2">Yearly</a>
                </li>
              </ul> */}
            </div>
            {/* .cs-tabs_heading */}
            <div className="cs-height_85 cs-height_lg_20" />
            <div className="cs-tab_body">
              <div className="cs-side_header_box" style={{ width: "50%" }}>
                {/* <h3 className="cs-side_header_title cs-primary_color">
                  Subscribe
                </h3> */}
                <div className="cs-newsletter cs-style1">
                  <form action="#" className="cs-newsletter_form">
                    <input
                      type="email"
                      className="cs-newsletter_input"
                      placeholder="example@work.com"
                      style={{ backgroundColor: "#181818" }}
                    />
                    <button className="cs-newsletter_btn">
                      <span>Send</span>
                    </button>
                  </form>
                  <div className="cs-newsletter_text">
                    Subscribe now for updates on pricing, news, and expert
                    insights – stay informed with ElecDraw
                  </div>
                </div>
              </div>
              {/* <div className="cs-tab" id="tab_1">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Standard</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$59/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Static responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Video marketing</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Keywords research</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Professional</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$299/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Static responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Content creating</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>High quality keywords</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Ultimate</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$599/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Dynamic responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Video marketing</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Keywords research</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                </div>
              </div> */}
              {/* .cs-tab */}
              {/* <div className="cs-tab active" id="tab_2">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Standard</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$99/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Static responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Video marketing</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Keywords research</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Professional</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$499/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Static responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Content creating</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>High quality keywords</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                  <div className="col-lg-4">
                    <div className="cs-pricing_table cs-style1">
                      <h2 className="cs-pricing_title">Ultimate</h2>
                      <div className="cs-pricing_info">
                        <div className="cs-price">
                          <h3 className="cs-white_color">$799/</h3>
                          <span className="cs-accent_color">Yearly</span>
                        </div>
                        <div className="cs-price_text">
                          Per subscriber per year
                        </div>
                      </div>
                      <ul className="cs-pricing_feature cs-mp0">
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Dynamic responsive website</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Video marketing</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Keywords research</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Facebook campaign</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>eCommerce solution</span>
                        </li>
                        <li>
                          <span className="cs-feature_icon cs-accent_color">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 10ZM0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H6L6 11H10V14L14 10L10 6L10 9Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          <span>Google adword</span>
                        </li>
                      </ul>
                      <div className="cs-pricing_btn_wrap">
                        <a href="#" className="cs-text_btn">
                          <span>Purchase Now</span>
                          <svg
                            width={26}
                            height={12}
                            viewBox="0 0 26 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M25.5303 6.53033C25.8232 6.23744 25.8232 5.76256 25.5303 5.46967L20.7574 0.696699C20.4645 0.403806 19.9896 0.403806 19.6967 0.696699C19.4038 0.989593 19.4038 1.46447 19.6967 1.75736L23.9393 6L19.6967 10.2426C19.4038 10.5355 19.4038 11.0104 19.6967 11.3033C19.9896 11.5962 20.4645 11.5962 20.7574 11.3033L25.5303 6.53033ZM0 6.75H25V5.25H0V6.75Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div className="cs-height_25 cs-height_lg_25" />
                  </div>
                </div>
              </div> */}
              {/* .cs-tab */}
            </div>
            {/* .cs-tab_body */}
          </div>
          {/* .cs-tabs */}
        </div>
      </section>
      {/* End Pricing Plan */}
    </>
  );
};

export { Pricing };
